import EasyStar from 'easystarjs';
import confetti from 'canvas-confetti'
import { LEVEL } from './levels';
import { PLAYER_FLIP } from './player-flip';
import { GEM_CONSUMED } from './gem-consumed';
import { PIECES_SCATTER } from './pieces-scatter';

declare var require: any;
declare global { interface Window { background: any; } }
const TRACK1 = require('../audio/track-1.mp3');
const TRACK2 = require('../audio/track-2.mp3');
const TRACK3 = require('../audio/track-3.mp3');
const TRACK4 = require('../audio/track-4.mp3');
const TRACK5 = require('../audio/track-5.mp3');
const TRACK6 = require('../audio/track-6.mp3');
const TRACK7 = require('../audio/track-7.mp3');
const TRACK8 = require('../audio/track-8.mp3');
const TRACK9 = require('../audio/track-9.mp3');
const TRACK10 = require('../audio/track-10.mp3');
const APP_NAME = 'end-of-run';

export class EndOfRun {

  private host: HTMLElement;
  private soundSetting: boolean;
  private musicSetting: boolean;
  private tutorialSetting: boolean;
  private levelSetting: number;
  private nextMove: string;
  private level: number;
  private yDown: number;
  private xDown: number;
  private player: number;
  private isPlayerMoving: boolean;
  private playerY: number;
  private playerX: number;
  private gemsRemaining: number;
  private gemConsumed: boolean;
  private easystar: any;
  private board: Array<Array<number>>;
  private map: Array<Array<number>>;

  constructor(host: HTMLElement) {
    this.host = host;
    this.soundSetting = true;
    this.musicSetting = true;
    this.tutorialSetting = true;
    this.levelSetting = 0;
    this.nextMove = null;
    this.level = 0;
    this.yDown = 0;
    this.xDown = 0;
    this.player = 0;
    this.isPlayerMoving = false;
    this.playerY = 0;
    this.playerX = 0;
    this.gemsRemaining = 20;
    this.gemConsumed = false;
    this.easystar = new EasyStar.js();
    this.board = [];
    this.map = [];
  }

  public init(): void {
    this.render();
    this.bindEvents();
  }

  public bindEvents(): void {
    this.host.addEventListener('click', (event: Event) => {
      const targetEl = event.target as HTMLButtonElement;
      if (targetEl.closest('#start') !== null) {
        this.render();
      }
      if (targetEl.closest('#play') !== null) {
        this.renderLevel();
      }
      if (targetEl.closest('#settings') !== null) {
        this.renderSettings();
      }
      if (targetEl.closest('#skip-tutorial') !== null) {
        this.updateLocalStorageSettings('tutorial', 'false');
        this.removeTutorial();
      }
      if (targetEl.closest('[data-tutorial-continue]') !== null) {
        const step = targetEl.getAttribute('data-tutorial-continue');
        this.renderTutorialStep(step);
      }
    });
    this.host.addEventListener('change', (event: Event) => {
      const targetEl = event.target as HTMLInputElement;
      const id = targetEl.id;
      const setting = targetEl.checked;
      if (targetEl.closest('#sound') !== null) {
        this.updateLocalStorageSettings(id, `${setting}`);
        targetEl.setAttribute('checked', `${setting}`);
      }
      if (targetEl.closest('#music') !== null) {
        this.updateLocalStorageSettings(id, `${setting}`);
        targetEl.setAttribute('checked', `${setting}`);
      }
      if (targetEl.closest('#tutorial') !== null) {
        this.updateLocalStorageSettings(id, `${setting}`);
        targetEl.setAttribute('checked', `${setting}`);
      }
      if (targetEl.closest('#set-level') !== null) {
        const level = parseInt(targetEl.value);
        this.level = level;
        this.setLevel(level);
      }
    });
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      // Left Key
      if (event.keyCode === 37) {
        this.moveLeft();
      }
      // Up Key
      if (event.keyCode === 38) {
        this.moveUp();
      }
      // Right Key
      if (event.keyCode === 39) {
        this.moveRight();
      }
      // Down Key
      if (event.keyCode === 40) {
        this.moveDown();
      }
    });
    // Disable multitouch zoom - https://stackoverflow.com/a/37712966
    this.host.addEventListener('touchstart', (event: any) => {
      if(event.touches.length > 1) {
        event.preventDefault();
      }
    });
    document.addEventListener('touchstart', (event) => this.handleTouchStart(event), false);
    document.addEventListener('touchmove', (event) => this.handleTouchMove(event), false);
    document.addEventListener('transitionstart', (event) => {
      const targetEl = event.target as HTMLElement;
      const player = targetEl.closest('.player') as HTMLElement;
      if (player !== null) {
        this.flipSound();
        const targetEl = event.target as HTMLElement;
        if (targetEl.closest('.player') !== null) {
          this.isPlayerMoving = true;
        }
      }
    });
    document.addEventListener('transitionend', (event) => {
      const targetEl = event.target as HTMLElement;
      const player = targetEl.closest('.player') as HTMLElement;
      if (player !== null) {
        if (this.nextMove === 'UP') { --this.playerY }
        if (this.nextMove === 'DOWN') { ++this.playerY }
        if (this.nextMove === 'LEFT') { --this.playerX }
        if (this.nextMove === 'RIGHT') { ++this.playerX }
        this.isPlayerMoving = false;
        this.updatePlayer();
        this.checkForValidPath();
        if (this.gemConsumed) {
          this.consumeGemSound();
          --this.gemsRemaining;
          if (this.gemsRemaining === 0) {
            ++this.level;
            this.renderLevel();
          } else {
            this.map = JSON.parse(JSON.stringify(this.board));
            this.renderBoard().then(() => {
              this.renderPlayer();
              this.generateGem();
            });
          }
        }
      }
    });
  }

  public checkForValidPath(): void {
    const gem = this.host.querySelector('.gem');
    if (gem !== null) {
      const gemY = parseInt(gem.getAttribute('data-axis-y'));
      const gemX = parseInt(gem.getAttribute('data-axis-x'));
      const gemValue = parseInt(gem.getAttribute('data-current-number'));
      const nextPlayerValue = this.player === 5 ? 1 : this.player + 1;
      const mapHeight = this.map.length;
      const mapWidth = this.map[0].length;
      this.easystar.setGrid(this.map);
      this.easystar.setAcceptableTiles([0]);
      this.easystar.findPath(this.playerX, this.playerY, gemX, gemY, (path: any) => {
        if (path === null) {
          this.renderGameOver('No more moves!');
        }
        // check if all pieces around player are blank
        const playerUpAvailable = this.host.querySelector(`[data-axis-y="${this.playerY - 1}"][data-axis-x="${this.playerX}"][data-active="true"]:not([class*="gem"])`);
        const playerDownAvailable = this.host.querySelector(`[data-axis-y="${this.playerY + 1}"][data-axis-x="${this.playerX}"][data-active="true"]:not([class*="gem"])`);
        const playerLeftAvailable = this.host.querySelector(`[data-axis-y="${this.playerY}"][data-axis-x="${this.playerX - 1}"][data-active="true"]:not([class*="gem"])`);
        const playerRightAvailable = this.host.querySelector(`[data-axis-y="${this.playerY}"][data-axis-x="${this.playerX + 1}"][data-active="true"]:not([class*="gem"])`);
        if (playerUpAvailable === null && playerDownAvailable === null && playerLeftAvailable === null && playerRightAvailable === null && gemValue !== nextPlayerValue) {
         this.renderGameOver('No more moves!');
        }
        // check if all pieces around gem are blank
        const gemUpAvailable = this.host.querySelector(`[data-axis-y="${gemY - 1}"][data-axis-x="${gemX}"][data-active="true"]:not([class*="player"])`);
        const gemDownAvailable = this.host.querySelector(`[data-axis-y="${gemY + 1}"][data-axis-x="${gemX}"][data-active="true"]:not([class*="player"])`);
        const gemLeftAvailable = this.host.querySelector(`[data-axis-y="${gemY}"][data-axis-x="${gemX - 1}"][data-active="true"]:not([class*="player"])`);
        const gemRightAvailable = this.host.querySelector(`[data-axis-y="${gemY}"][data-axis-x="${gemX + 1}"][data-active="true"]:not([class*="player"])`);
        if (gemUpAvailable === null && gemDownAvailable === null && gemLeftAvailable === null && gemRightAvailable === null && gemValue !== nextPlayerValue) {
         this.renderGameOver('No more moves!');
        }
      });
      this.easystar.calculate();
    }
  }

  // https://stackoverflow.com/a/23230280
  public handleTouchStart(event: any): void {
    const firstTouch = event.touches[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  }

  // https://stackoverflow.com/a/23230280
  public handleTouchMove(event: any): void {
    if (! this.xDown || ! this.yDown) {
      return;
    }
    let xUp = event.touches[0].clientX;
    let yUp = event.touches[0].clientY;

    let xDiff = this.xDown - xUp;
    let yDiff = this.yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        // Left Swipe
        this.moveLeft();
      } else {
        // Right Swipe
        this.moveRight();
      }
    } else {
      if (yDiff > 0) {
        // Up Swipe
        this.moveUp();
      } else { 
        // Down Swipe
        this.moveDown();
      }
    }
    // Reset
    this.xDown = null;
    this.yDown = null;
  }

  public loadLocalStorageSettings(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Load Sound Setting
      const localStorageSoundSetting = localStorage.getItem(`${APP_NAME}-sound`);
      if (localStorageSoundSetting !== null) {
        this.soundSetting = JSON.parse(localStorageSoundSetting);
      } else {
        localStorage.setItem(`${APP_NAME}-sound`, 'true');
      }
      // Load Music Setting
      const localStorageMusicSetting = localStorage.getItem(`${APP_NAME}-music`);
      if (localStorageMusicSetting !== null) {
        this.musicSetting = JSON.parse(localStorageMusicSetting);
      } else {
        localStorage.setItem(`${APP_NAME}-music`, 'true');
      }
      // Load Tutorial Setting
      const localStorageTutorialSetting = localStorage.getItem(`${APP_NAME}-tutorial`);
      if (localStorageTutorialSetting !== null) {
        this.tutorialSetting = JSON.parse(localStorageTutorialSetting);
      } else {
        localStorage.setItem(`${APP_NAME}-tutorial`, 'true');
      }
      // Load Level Setting
      const localStorageLevelSetting = localStorage.getItem(`${APP_NAME}-level`);
      if (localStorageLevelSetting !== null) {
        this.levelSetting = JSON.parse(localStorageLevelSetting);
      } else {
        localStorage.setItem(`${APP_NAME}-level`, '0');
      }
      resolve();
    });
  }

  public updateLocalStorageSettings(id: string, setting: string): void {
    const localStorageSetting = localStorage.getItem(`${APP_NAME}-${id}`);
    if (localStorageSetting !== null) {
      localStorage.setItem(`${APP_NAME}-${id}`, setting);
      if (id === 'sound') { this.soundSetting = JSON.parse(setting); }
      if (id === 'music') { this.musicSetting = JSON.parse(setting); }
      if (id === 'tutorial') { this.tutorialSetting = JSON.parse(setting); }
      if (id === 'level') { this.levelSetting = JSON.parse(setting); }
    }
  }

  public render(): void {
    this.loadLocalStorageSettings().then(() => {
      this.setBackgroundColor();
      this.player = 1;
      this.playerY = 0;
      this.playerX = 0;
      this.level = this.levelSetting || 0;
      this.host.innerHTML = '';
      this.host.innerHTML = `
        <div class="container">
          <div class="text-center">
            <h1 class="logo">End of Run</h1>
            <div class="padding-top-0-5rem">
              <button class="button" id="play" type="button">
                Play
              </button>
            </div>
            <div class="padding-top-0-5rem">
              <div class="select">
                <span class="set-level">
                  Continue from level ${this.levelSetting + 1}
                </span>
                <select id="set-level"></select>
              </div>
            </div>
            <div class="padding-top-0-5rem">
              <button class="button" id="settings" type="button">
                Settings
              </button>
            </div>
          </div>
        </div>
      `;
      const setLevelEl = this.host.querySelector('#set-level');
      let levels = '';
      for (let i = 0; i < this.levelSetting + 1; i++) {
        levels += `<option ${i === this.levelSetting && 'selected'} value="${i}">Continue from level ${i + 1}</option>`;
      }
      if (setLevelEl !== null) {
        setLevelEl.innerHTML = `${levels}`;
      }
    });
  }

  public renderSettings(): void {
    this.loadLocalStorageSettings().then(() => {
      this.host.innerHTML = '';
      this.host.innerHTML = `
        <div class="container">
          <div class="text-center">
            <h1>Settings</h1>
            <div class="padding-top-0-5rem">
              <div class="toggle">
                <input id="sound" class="toggle-checkbox sr" type="checkbox" ${this.soundSetting && 'checked'} />
                <label for="sound" class="toggle-label">
                  <span class="label-inner">
                    Sound
                  </span>
                </label>
              </div>
            </div>
            <div class="padding-top-0-5rem">
              <div class="toggle">
                <input id="music" class="toggle-checkbox sr" type="checkbox" ${this.musicSetting && 'checked'} />
                <label for="music" class="toggle-label">
                  <span class="label-inner">
                    Music
                  </span>
                </label>
              </div>
            </div>
            <div class="padding-top-0-5rem">
              <div class="toggle">
                <input id="tutorial" class="toggle-checkbox sr" type="checkbox" ${this.tutorialSetting && 'checked'} />
                <label for="tutorial" class="toggle-label">
                  <span class="label-inner">
                    Tutorial
                  </span>
                </label>
              </div>
            </div>
            <div class="padding-top-1rem">
              <button class="button" id="start" type="button">
                Save and go back
              </button>
            </div>
          </div>
        </div>
      `;
    });
  }

  public renderLevel(): void {
    this.map = null;
    if (this.level < LEVEL.length) {
      const level = LEVEL[this.level];
      const unlockedLevel = this.level > this.levelSetting ? ++this.levelSetting : this.levelSetting;
      this.updateLocalStorageSettings('level', `${unlockedLevel}`);
      const { playerY, playerX, board, target } = level;
      this.board = board;
      this.player = 0;
      this.nextMove = null;
      this.playerY = playerY;
      this.playerX = playerX;
      this.gemsRemaining = target;
      this.host.innerHTML = '';
      this.host.innerHTML = `
        <div class="container">
          <div id="status" class="status">
            <div class="status-container text-center">
              <div class="level">
                Level <strong id="level">${this.level + 1}</strong> &nbsp;
              </div>
              <div class="gems">
                Gems Remaining <strong id="gems-remaining">${this.gemsRemaining}</strong>
              </div>
            </div>
          </div>
          <div id="board" class="board"></div>
          <audio id="game-music" class="none" loop controls><source src="" type="audio/mpeg"></audio>
        </div>
      `;
      this.renderLevelNumber();
      this.startMusic();
      this.renderBoard().then(() => {
        this.updatePlayer();
        this.generateGem();
        if (this.tutorialSetting) {
          this.renderTutorialStep();
        }
      });
    } else {
      this.renderSuccess();
    }
  }

  public renderTutorialStep(step?: string): void {
    const stepAsNumber = parseInt(step);
    switch(stepAsNumber) {
      case 1: {
        const modal = this.host.querySelector('.modal-tutorial .modal-inner');
        if (modal !== null) {
          modal.innerHTML = '';
          modal.innerHTML = `
            <div class="player-tutorial"></div>
            <p class="strong">
              Each move will give a count cycle from 1 - 5
            </p>
            <div class="padding-top-0-5rem">
              <button data-tutorial-continue="2" class="button" type="button">
                Continue
              </button>
            </div>
            <div class="padding-top-0-5rem">
              <button class="button" id="skip-tutorial" type="button">
                Skip tutorial
              </button>
            </div>
          `;
      }
        break;
      }
      case 2: {
        const modal = this.host.querySelector('.modal-tutorial .modal-inner');
        if (modal !== null) {
          modal.innerHTML = '';
          modal.innerHTML = `
            <div style="display: flex; justify-content: center; margin-bottom: 40px;">
              <div class="square-tutorial"></div>
              <div class="gem-tutorial"></div>
            </div>
            <p class="strong">
              Move the player to match the correct gem number
            </p>
            <div class="padding-top-0-5rem">
              <button id="skip-tutorial" class="button" type="button">
                Okay, I'm ready to play
              </button>
            </div>
          `;
        }
        break;
      }
      default: {
        this.host.insertAdjacentHTML('afterbegin', `
          <div class="modal modal-tutorial text-center">
            <div class="container">
              <div class="modal-inner">
                <div class="cursor"></div>
                <p class="strong">
                  Swipe in any direction to move the player block
                </p>
                <p class="strong">
                  Or use the arrow keys on your keyboard
                </p>
                <div class="padding-top-0-5rem">
                  <button data-tutorial-continue="1" class="button" type="button">
                    Continue
                  </button>
                </div>
                <div class="padding-top-0-5rem">
                  <button class="button" id="skip-tutorial" type="button">
                    Skip tutorial
                  </button>
                </div>
              </div>
            </div>
          </div>
        `);
        break;
      }
    }
  }

  public renderLevelNumber(): void {
    const levelElement = this.host.querySelector('#level');
    if (levelElement !== null) {
      levelElement.innerHTML = `${this.level + 1}`;
    }
  }

  public setLevel(level: number): void {
    const levelSelection = this.host.querySelector('.set-level');
    if (levelSelection !== null) {
      levelSelection.innerHTML = `Continue from level ${level + 1}`;
    }
  }

  public renderBoard(): Promise<void> {
    return new Promise((resolve, reject) => {
      const boardElement = this.host.querySelector('#board');
      if (boardElement !== null) {
        boardElement.innerHTML = '';
      }
      const gemsRemainingElement = this.host.querySelector('#gems-remaining');
      if (gemsRemainingElement !== null) {
        gemsRemainingElement.innerHTML = `${this.gemsRemaining}`;
      }
      this.map = JSON.parse(JSON.stringify(this.board));
      this.board.map((row: Array<number>, rowIndex: number) => {
        if (this.board !== null) {
          boardElement.insertAdjacentHTML('beforeend', `
            <div id="row-${rowIndex}" class="row-grid"></div>`
          );
        }
        row.map((column: number, columnIndex: number) => {
          const rowElement = this.host.querySelector(`#row-${rowIndex}`);
          const isActive = column <= 0 ? true : false;
          if (rowElement !== null) {
            rowElement.insertAdjacentHTML('beforeend', `
              <div
                data-axis-y="${rowIndex}"
                data-axis-x="${columnIndex}"
                data-active="${isActive}"
                class="square"
              >
              </div>`);
          }
        });
      });
      resolve();
    });
  }

  public removeStatusBar(): void {
    const statusBar = this.host.querySelector('#status') as HTMLElement;
    if (statusBar !== null) {
      statusBar.style.transform = 'translate(0px, -100px)';
      statusBar.style.transitionDuration = '.3s';
      statusBar.style.transitionProperty = 'all';
    }
  }

  public removePlayer(): void {
    const player = this.host.querySelector('.player');
    if (player !== null) {
      player.classList.remove('player');
    }
  }

  public removeGem(): void {
    const gem = this.host.querySelector('.gem');
    if (gem !== null) {
      gem.classList.remove('gem');
    }
  }

  public removeBoard(): void {
    const board = this.host.querySelector('#board');
    if (board !== null) {
      board.classList.add('disable');
    }
    const allSquares = this.host.querySelectorAll('.square');
    allSquares.forEach((element: HTMLElement, elementIndex: number) => {
      element.innerHTML = '';
      element.classList.remove('player');
      element.classList.remove('gem');
      element.removeAttribute('data-direction');
      element.removeAttribute('style');
      element.style.transform = 'translate(0px, 3000%)';
      element.style.transitionDuration = '.3s';
      element.style.transitionProperty = 'all';
      element.style.transitionDelay = `0.${elementIndex * 5}s`;
    });
  }

  public removeTutorial(): void {
    const tutorialModal = this.host.querySelector('.modal-tutorial');
    if (tutorialModal !== null) {
      tutorialModal.remove();
    }
  }

  public renderGameOver(reason: string): void {
    this.removeTutorial();
    this.scatterPiecesSound();
    this.removeStatusBar();
    this.removePlayer();
    this.removeGem();
    this.removeBoard();
    this.host.insertAdjacentHTML('afterbegin', `
      <div class="modal text-center">
        <div class="container">
          <div class="modal-inner">
            <h1>${reason}</h1>
            <div class="padding-top-0-5rem">
              <button class="button" id="play" type="button">
                Replay level ${this.level + 1}
              </button>
            </div>
            <div class="padding-top-0-5rem">
              <button class="button" id="start" type="button">
                Back to main screen
              </button>
            </div>
          </div>
        </div>
      </div>
    `);
  }

  public renderSuccess(): void {
    this.removeStatusBar();
    this.removePlayer();
    this.removeGem();
    this.removeBoard();
    this.host.insertAdjacentHTML('afterbegin', `
      <div class="modal modal-secondary text-center">
        <div class="container">
          <div class="modal-inner">
            <h1>Congratulations!</h1>
            <p class="strong">
              You completed all the levels in End of Run.
            </p>
            <div class="padding-top-0-5rem">
              <button class="button" id="start" type="button">
                Start a new game
              </button>
            </div>
            <div class="padding-top-0-5rem">
              <button class="button" id="start" type="button">
                Back to main screen
              </button>
            </div>
          </div>
        </div>
      </div>
    `);
    this.generateConfetti();
  }

  public generateConfetti(): void {
    let end = Date.now() + (1 * 1000);
    let colors = ['#8c0800', '#0074d9', '#01ff70', '#ffdc00', '#b10dc9'];
    (function frame() {
      confetti({
        particleCount: 5,
        angle: 60,
        spread: 200,
        origin: { x: 0 },
        colors: colors
      });
      confetti({
        particleCount: 5,
        angle: 120,
        spread: 200,
        origin: { x: 1 },
        colors: colors
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
  }

  public generateGem(): void {
    const generateGem = Math.floor(Math.random() * 5) + 1;
    // const generateGem = 4;
    const allAvailableSpaces = this.host.querySelectorAll('[data-active="true"]:not([class*="player"])');
    const generateRandomPlace = Math.floor(Math.random() * allAvailableSpaces.length);
    // const generateRandomPlace = 54;
    const gem = allAvailableSpaces[generateRandomPlace] as HTMLElement;
    if (gem !== null) {
      gem.removeAttribute('style');
      gem.removeAttribute('data-direction');
      gem.classList.add('gem');
      gem.setAttribute('data-current-number', `${generateGem}`);
      gem.style.backgroundColor = 'transparent';
      gem.style.color = this.mapColor(generateGem);
      gem.innerHTML = `${generateGem}`;
      this.setBackgroundColor(generateGem);
    }
    this.gemConsumed = false;
  }

  public movePlayer(direction: string): void {
    const currentGem = this.host.querySelector('.gem') as HTMLElement;
    if (!this.isPlayerMoving) {
      if (currentGem !== null) {
        const previousY = this.playerY;
        const previousX = this.playerX;
        const predictPlayer = this.player === 5 ? 1 : this.player + 1;
        const currentGemNumber = parseInt(currentGem.getAttribute('data-current-number'));
        switch(direction) {
          case 'UP': {
            // check if next move playable
            const isUpAvailable = this.map[this.playerY - 1];
            if (typeof isUpAvailable !== 'undefined' && this.map[this.playerY - 1][this.playerX] === 0) {
              // check if next move is a gem
              const isGemNext = this.host.querySelector(`.gem[data-axis-y="${this.playerY - 1}"][data-axis-x="${this.playerX}"]`) as HTMLElement;
              if (isGemNext) {
                // next move is a gem and number matches - gem consumed
                if (currentGemNumber === predictPlayer) {
                  this.gemConsumed = true;
                  this.animatePlayer('UP');
                }
              } else {
                this.animatePlayer('UP');
              }
            }
            break;
          }
          case 'DOWN': {
            // check if next move playable
            const isDownAvailable = this.map[this.playerY + 1];
            if (typeof isDownAvailable !== 'undefined' && this.map[this.playerY + 1][this.playerX] === 0) {
              // check if next move is a gem
              const isGemNext = this.host.querySelector(`.gem[data-axis-y="${this.playerY + 1}"][data-axis-x="${this.playerX}"]`) as HTMLElement;
              if (isGemNext) {
                // next move is a gem and number matches - gem consumed
                if (currentGemNumber === predictPlayer) {
                  this.gemConsumed = true;
                  this.animatePlayer('DOWN');
                }
              } else {
                this.animatePlayer('DOWN');
              }
            }
            break;
          }
          case 'LEFT': {
            // check if next move playable
            const isLeftAvailable = this.map[this.playerY][this.playerX - 1];
            if (typeof isLeftAvailable !== 'undefined' && this.map[this.playerY][this.playerX - 1] === 0) {
              // check if next move is a gem
              const isGemNext = this.host.querySelector(`.gem[data-axis-y="${this.playerY}"][data-axis-x="${this.playerX - 1}"]`) as HTMLElement;
              if (isGemNext) {
                // next move is a gem and number matches - gem consumed
                if (currentGemNumber === predictPlayer) {
                  this.gemConsumed = true;
                  this.animatePlayer('LEFT');
                }
              } else {
                this.animatePlayer('LEFT');
              }
            }
            break;
          }
          case 'RIGHT': {
            // check if next move playable
            const isRightAvailable = this.map[this.playerY][this.playerX + 1];
            if (typeof isRightAvailable !== 'undefined' && this.map[this.playerY][this.playerX + 1] === 0) {
              // check if next move is a gem
              const isGemNext = this.host.querySelector(`.gem[data-axis-y="${this.playerY}"][data-axis-x="${this.playerX + 1}"]`) as HTMLElement;
              if (isGemNext) {
                // next move is a gem and number matches - gem consumed
                if (currentGemNumber === predictPlayer) {
                  this.gemConsumed = true;
                  this.animatePlayer('RIGHT');
                }
              } else {
                this.animatePlayer('RIGHT');
              }
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  public updatePlayer(): void {
    this.player = this.calculatePlayer();
    const currentPlayer = this.host.querySelector('.player');
    if (currentPlayer !== null) {
      currentPlayer.innerHTML = '';
      currentPlayer.classList.remove('player');
      currentPlayer.classList.remove('gem');
      currentPlayer.removeAttribute('data-current-number');
      currentPlayer.removeAttribute('data-direction');
      currentPlayer.removeAttribute('style');
    }
    this.renderPlayer();
  }

  public renderPlayer(): void {
    const player = this.host.querySelector(`[data-axis-y="${this.playerY}"][data-axis-x="${this.playerX}"]`) as HTMLElement;
    if (player !== null) {
      player.removeAttribute('style');
      player.removeAttribute('data-direction');
      player.classList.add('player');
      player.classList.remove('gem');
      player.setAttribute('data-current-number', `${this.player}`);
      player.style.backgroundColor = this.mapBackgroundColor(this.player);
      player.style.color = this.mapColor(this.player);
      player.innerHTML = `${this.player}`;
    }
  }

  public calculatePlayer(): number {
    if (this.player === 5) {
      return this.player = 1;
    }
    return ++this.player;
  }

  public moveUp(): void {
    this.movePlayer('UP');
  }
  public moveDown(): void {
    this.movePlayer('DOWN');
  }
  public moveLeft(): void {
    this.movePlayer('LEFT');
  }
  public moveRight(): void {
    this.movePlayer('RIGHT');
  }

  public animatePlayer(direction: string): void {
    const player = this.host.querySelector('.player') as HTMLElement;
    let move;
    if (direction === 'UP') {
      this.nextMove = 'UP';
      move = 'UP';
    }
    if (direction === 'DOWN') {
      this.nextMove = 'DOWN';
      move = 'DOWN';
    }
    if (direction === 'LEFT') {
      this.nextMove = 'LEFT';
      move = 'LEFT';
    }
    if (direction === 'RIGHT') {
      this.nextMove = 'RIGHT';
      move = 'RIGHT';
    }
    if (player !== null) {
      player.setAttribute('data-direction', move);
      player.style.transitionDuration = '.2s';
      player.style.transitionProperty = 'all';
      player.style.zIndex = '10';
      player.style.backgroundColor = this.mapBackgroundColor(this.player);
      player.style.color = this.mapColor(this.player);
      player.setAttribute('data-active', 'false');
      this.map[this.playerY][this.playerX] = 1;
    }
  }

  public mapColor(number: number): string {
    switch(number) {
      case 3:
      case 4: return '#000';
      default: return '#fff';
    }
  }

  public mapBackgroundColor(number: number): string {
    switch(number) {
      case 1: return '#8c0800';
      case 2: return '#0074d9';
      case 3: return '#01ff70';
      case 4: return '#ffdc00';
      case 5: return '#b10dc9';
      default: return '#001f3f';
    }
  }

  public setBackgroundColor(number?: number): void {
    const backgroundColor = this.mapBackgroundColor(number);
    if (typeof window.background.setOptions === 'function') {
      window.background.setOptions({
        backgroundColor: backgroundColor
      });
    }
  }

  public generateTrack(): Promise<string> {
    return new Promise((resolve, reject) => {
      const track = this.level + 1;
      const audio = this.host.querySelector('#game-music') as HTMLAudioElement;
      const audioSrc = audio.querySelector('source') as any;
      switch(track) {
        case 1:
        case 11:
        case 21: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK1;
            resolve();
          }
          break;
        }
        case 2:
        case 12:
        case 22: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK2;
            resolve();
          }
          break;
        }
        case 3:
        case 13:
        case 23: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK3;
            resolve();
          }
          break;
        }
        case 4:
        case 14:
        case 24: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK4;
            resolve();
          }
          break;
        }
        case 5:
        case 15:
        case 25: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK5;
            resolve();
          }
          break;
        }
        case 6:
        case 16:
        case 26: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK6;
            resolve();
          }
          break;
        }
        case 7:
        case 17:
        case 27: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK7;
            resolve();
          }
          break;
        }
        case 8:
        case 18:
        case 28: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK8;
            resolve();
          }
          break;
        }
        case 9:
        case 19:
        case 29: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK9;
            resolve();
          }
          break;
        }
        case 10:
        case 20:
        case 30: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK10;
            resolve();
          }
          break;
        }
        default: {
          if (audioSrc !== null) {
            audioSrc.src = TRACK1;
            resolve();
          }
          break;
        }
      }
    });
  }

  public startMusic(): void {
    if (this.musicSetting) {
      this.generateTrack().then(() => {
        const music = this.host.querySelector('#game-music') as HTMLAudioElement;
        if (music !== null) {
          music.currentTime = 0;
          music.load();
          music.play();
        }
      });
    }
  }

  public flipSound(): void {
    if (this.soundSetting) {
      const audio = new Audio(PLAYER_FLIP);
      audio.currentTime = 0;
      audio.load();
      audio.play();
    }
  }

  public consumeGemSound(): void {
    if (this.soundSetting) {
      const audio = new Audio(GEM_CONSUMED);
      audio.currentTime = 0;
      audio.load();
      audio.play();
    }
  }

  public scatterPiecesSound(): void {
    if (this.soundSetting) {
      const audio = new Audio(PIECES_SCATTER);
      audio.currentTime = 0;
      audio.load();
      audio.play();
    }
  }
}

// Initialise the class to run
const app = document.querySelector('#app') as HTMLElement;
if (app !== null) {
  const application = new EndOfRun(app as HTMLElement);
  application.init();
}
