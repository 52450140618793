export const LEVEL = [
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 3,
    board: [
      [1,0,0,0,0,0,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,1,0,0,1,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [1,0,0,0,0,0,1],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 2,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,1,1,1,1,1],
      [0,0,1,1,1,1,1],
      [0,0,1,1,1,1,1],
      [0,0,1,1,1,1,1],
      [0,0,0,0,0,1,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  { 
    playerY: 1, 
    playerX: 0, 
    board: [  
      [1,1,0,0,0,1,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,1,0,1,0,1,0],
      [0,1,0,1,0,1,0],
      [0,1,0,1,0,1,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [1,1,0,0,0,1,1],
    ],
    target: 20, 
    time: 20  
  },
  {
    playerY: 1,
    playerX: 0,
    board: [
      [1,1,0,0,0,1,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [1,1,0,0,0,1,1],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,1],
      [0,0,1,1,0,1,1],
      [0,0,1,1,0,1,1],
      [0,0,1,1,0,1,1],
      [1,0,0,0,0,0,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,1,1,1,0,0],
      [0,0,1,1,1,0,0],
      [0,0,1,1,1,0,0],
      [0,0,1,1,1,0,0],
      [0,0,1,1,1,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,1,1,1,1],
      [0,0,0,1,1,1,1],
      [0,0,0,1,1,1,1],
      [0,0,0,1,1,1,1],
      [0,0,0,1,1,1,1],
      [0,0,0,1,1,1,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,1,0,1,0,1,0],
      [0,0,0,0,0,0,0],
      [0,1,0,1,0,1,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 3,
    board: [
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 3,
    board: [
      [1,0,0,0,0,0,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [1,0,0,0,0,0,1],
      [1,0,0,1,0,0,1],
      [1,0,0,1,0,0,1],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [1,0,0,0,0,0,1],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 3,
    board: [
      [1,1,0,0,0,1,1],
      [1,0,0,0,0,0,1],
      [1,0,0,1,0,0,1],
      [1,0,0,1,0,0,1],
      [1,0,0,1,0,0,1],
      [1,0,0,1,0,0,1],
      [1,0,0,1,0,0,1],
      [1,0,0,0,0,0,1],
      [1,1,0,0,0,1,1],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 3,
    board: [
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [0,0,0,0,0,0,0],
      [0,0,1,1,0,0,0],
      [0,0,1,1,0,0,0],
      [0,0,1,1,0,0,0],
      [0,0,1,1,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 2,
    playerX: 3,
    board: [
      [1,0,0,1,0,0,1],
      [1,0,0,0,0,0,1],
      [1,0,0,0,0,0,1],
      [0,0,0,0,0,0,0],
      [0,0,1,1,1,0,0],
      [0,0,1,1,1,0,0],
      [1,0,0,0,0,0,1],
      [1,0,0,0,0,0,1],
      [1,0,0,1,0,0,1],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 7,
    playerX: 4,
    board: [
      [1,1,1,1,1,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,0,1,1],
      [1,1,0,0,1,1,1],
      [1,1,0,0,1,1,1],
      [1,1,0,0,0,0,0],
      [1,1,0,0,0,0,0],
      [1,1,0,0,0,0,0],
      [1,1,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [1,1,0,1,1,0,0],
      [1,1,0,1,1,0,0],
      [1,1,0,0,0,0,0],
      [1,1,0,1,1,0,0],
      [1,1,0,1,1,0,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 3,
    playerX: 4,
    board: [
      [1,1,1,1,1,1,1],
      [1,1,1,1,1,1,1],
      [1,1,1,0,0,0,0],
      [1,1,1,0,0,0,0],
      [1,1,1,0,0,0,0],
      [1,1,1,0,0,0,0],
      [1,1,1,0,0,1,1],
      [1,1,1,1,1,1,1],
      [1,1,1,1,1,1,1],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,0,1,1,1,0,0],
      [0,0,1,1,0,0,0],
      [0,0,1,1,0,0,0],
      [0,0,1,1,1,1,1],
      [0,0,1,1,1,1,1],
      [0,0,1,1,1,1,1],
      [0,0,1,1,1,1,1],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 0,
    board: [
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,1,0,0,0,1,0],
      [0,0,1,1,1,0,0],
      [0,0,1,1,1,0,0],
      [0,0,1,1,1,0,0],
      [0,1,0,0,0,1,0],
      [0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 0,
    playerX: 3,
    board: [
      [1,0,0,0,0,0,0],
      [1,0,1,1,0,0,0],
      [1,0,1,1,0,0,0],
      [1,0,0,0,0,0,0],
      [1,0,0,0,0,0,1],
      [1,1,1,1,0,0,1],
      [1,1,1,1,0,0,1],
      [1,1,1,1,0,0,0],
      [1,1,1,1,0,0,0],
    ],
    target: 20,
    time: 20
  },
  {
    playerY: 1,
    playerX: 0,
    board: [
      [1,0,0,0,0,0,0],
      [0,0,0,0,0,0,0],
      [0,1,0,0,0,1,0],
      [0,1,0,0,0,1,0],
      [0,0,0,1,0,0,0],
      [0,0,0,1,0,0,0],
      [0,1,0,0,0,1,0],
      [0,1,0,0,0,1,0],
      [0,0,0,0,0,0,0],
    ],
    target: 20,
    time: 20
  },
];
